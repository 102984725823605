import React from 'react';
import csx from 'classnames';
import { ErrorBoundary } from 'react-error-boundary';

import { Button } from 'antd';

const ErrorFallback = () => {
  return (
    <div className={csx('page-wrapper', 'flex-col', 'align-middle')}>
      <h4 className={csx('padding-md')}>Ops! Something went wrong...</h4>
      <Button href="/">Back to home</Button>
    </div>
  );
};

const myErrorHandler = (error, info) => {
  // Log error somewhere
  console.error(error, info);
};

const AppErrorBoundary = ({ children }) => {
  return (
    <ErrorBoundary FallbackComponent={ErrorFallback} onError={myErrorHandler}>
      {children}
    </ErrorBoundary>
  );
};

export default AppErrorBoundary;
