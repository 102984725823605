import { createStore, applyMiddleware, compose } from 'redux';
import { getFirebase } from 'react-redux-firebase';
import thunk from 'redux-thunk';

import rootReducer from './rootReducer';

const composeEnhancers =
   process.env.NODE_ENV === 'development'
      ? window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__
      : compose;

const store = createStore(
   rootReducer,
   composeEnhancers(applyMiddleware(thunk.withExtraArgument({ getFirebase })))
);

export default store;
