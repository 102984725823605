import {
  ORDER_SET_VALUES,
  ORDER_CLEAR_ALL,
  ORDER_SET_LOADING,
  ORDER_SET_MODE,
  ORDER_OPEN_MODAL,
  ORDER_SET_WIP_ITEM,
  ORDER_SET_WIP_MODE,
} from 'redux/types';

const initialState = {
  status: null,
  mode: null,
  data: null,
  openModal: null,
  wipItem: null,
  wipMode: { mode: null, index: null },
};

const requestReducer = (state = initialState, { type, payload }) => {
  switch (type) {
    case ORDER_SET_VALUES:
      return {
        ...state,
        data: {
          ...state.data,
          ...payload,
        },
      };
    case ORDER_CLEAR_ALL:
      return {
        ...state,
        data: payload,
      };
    case ORDER_SET_MODE:
      return {
        ...state,
        mode: payload,
      };
    case ORDER_SET_LOADING:
      return {
        ...state,
        status: payload,
      };
    case ORDER_OPEN_MODAL:
      return {
        ...state,
        openModal: payload,
      };
    case ORDER_SET_WIP_ITEM:
      return {
        ...state,
        wipItem: payload,
      };
    case ORDER_SET_WIP_MODE:
      return {
        ...state,
        wipMode: payload,
      };
    default:
      return state;
  }
};

export default requestReducer;
