import React, { Suspense, lazy } from 'react';
import { BrowserRouter, Route, Switch, Redirect } from 'react-router-dom';

// Global styles
import 'styles/normalise.scss';
import 'styles/globals.scss';
import 'styles/utils.scss';

// Redux
import { Provider, useSelector } from 'react-redux';
import store from 'redux/store';

// Firebase
import firebase from 'firebase/app';
import 'firebase/auth';
import 'firebase/firestore';
import 'firebase/storage';
import { ReactReduxFirebaseProvider, isLoaded } from 'react-redux-firebase';
import { createFirestoreInstance } from 'redux-firestore';
import { CONFIG } from 'configs/firebase';

// Components
import AppErrorBoundary from 'components/ErrorBoundary';
import PrivateRoute from 'components/PrivateRoute';
import Loading from 'pages/Loading';

const NotFound = lazy(() => import('pages/404'));
const LoginPage = lazy(() => import('pages/Login'));
const HomePage = lazy(() => import('pages/Home'));
const OM = lazy(() => import('pages/OM'));
const Rider = lazy(() => import('pages/Rider'));

// Init Firebase
if (!firebase.apps.length) {
  firebase.initializeApp(CONFIG);
} else {
  firebase.app();
}
firebase.firestore();
firebase.storage();

// Firebase redux config
const rrfConfig = {
  userProfile: 'users',
  useFirestoreForProfile: true,
};

const rrfProps = {
  firebase,
  config: rrfConfig,
  dispatch: store.dispatch,
  createFirestoreInstance,
};

const AuthIsLoaded = ({ children }) => {
  const auth = useSelector((state) => state.firebase.auth);
  if (!isLoaded(auth)) return <Loading type="pre_loading" />;
  return children;
};

const App = () => {
  return (
    <AppErrorBoundary>
      <Provider store={store}>
        <ReactReduxFirebaseProvider {...rrfProps}>
          <BrowserRouter>
            <AuthIsLoaded>
              <Suspense fallback={<Loading type="app_loading" />}>
                <Switch>
                  <Route path="/404" exact={true} component={NotFound} />
                  <Route path="/login" exact={true} component={LoginPage} />
                  <PrivateRoute path="/" exact={true} component={HomePage} />
                  <PrivateRoute path="/om" exact={true} component={OM} />
                  <PrivateRoute path="/rider" exact={true} component={Rider} />
                  <Redirect to="/404" />
                </Switch>
              </Suspense>
            </AuthIsLoaded>
          </BrowserRouter>
        </ReactReduxFirebaseProvider>
      </Provider>
    </AppErrorBoundary>
  );
};

export default App;
