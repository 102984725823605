import React from 'react';
import csx from 'classnames';

import Spinner from 'components/Spinner';

const MESSAGES = {
  default: 'Loading...',
  pre_loading: 'Loading the application...',
  app_loading: 'Building the UI...',
  permissions: 'Loading permissions...',
  load_seed_data: 'Fetching data...',
};

const LoadingPage = ({ type = 'default' }) => {
  return (
    <div className={csx('page-wrapper', 'flex-col', 'align-middle')}>
      <Spinner className={csx('padding-md')} />
      <p>{MESSAGES[type]}</p>
    </div>
  );
};

export default LoadingPage;
