import React from 'react';

import { Spin } from 'antd';
import { LoadingOutlined } from '@ant-design/icons';

const Spinner = ({ size = 24, ...rest }) => {
  const antIcon = <LoadingOutlined style={{ fontSize: size }} spin />;

  return <Spin indicator={antIcon} {...rest} />;
};

export default Spinner;
