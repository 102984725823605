// User
export const USER_SET_LOADING = 'USER_SET_LOADING';

// Request
export const ORDER_SET_VALUES = 'ORDER_SET_VALUES';
export const ORDER_CLEAR_ALL = 'ORDER_CLEAR_ALL';
export const ORDER_SET_LOADING = 'ORDER_SET_LOADING';
export const ORDER_SET_MODE = 'ORDER_SET_MODE';
export const ORDER_OPEN_MODAL = 'ORDER_OPEN_MODAL';
export const ORDER_SET_WIP_ITEM = 'ORDER_SET_WIP_ITEM';
export const ORDER_SET_WIP_MODE = 'ORDER_SET_WIP_MODE';
